import Service from './Service';

class RoleService extends Service {
    constructor() {
        super();
    }
    async get(pageParam) {
        return this.callApiGet(`/role${pageParam}`);
    }
    async create(postData) {
        return this.callApiPost(`/role`, postData);
    }
    async update(postData) {
        return this.callApiPut(`/role`, postData);
    }
    async delete(id) {
        return this.callApiDelete(`/role?_ids=${id}`);
    }
}
export default RoleService