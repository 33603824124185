<template>
  <v-container
    id="page-role"
    fluid
  >
    <base-wee-sketlon-loader
      :loading="state.loading"
      type="table-heading, table-thead, table-tbody, table-tfoot"
      :no="1"
    />
    <!-- Table  -->
    <wee-simple-table
      v-if="!state.loading"
      :title="$t('model.role.role')"
      :headers="fillableHeaders"
      :tr-list="filteredList"
      :pages="pages"
      :sort="sort"
      @update-search="searchTxt = $event"
      @on-item-click="onItemClick"
      @on-item-delete="onBeforeDeleteItem"
      @on-open-new-form="onOpenNewForm"
      @on-advance-search="advanceSearch"
      @on-reload-page="onReload"
    >
      <!-- <template v-slot:theader></template> 
      <template v-slot:tbody></template> 
      <template v-slot:tpaging></template>  -->
    </wee-simple-table>

    <role-form
      v-model="entity"
      :edit-mode="editMode"
      :open="openNewForm"
      :processing="isProcessing"
      :permissions="permissionList"
      @close="openNewForm = false"
      @save="onSave"
      
    />
    <wee-confirm ref="weeConfirmRef" />
    <wee-toast ref="weeToastRef" />
  </v-container>
</template>

<script>
// service
import RoleService from "@/api/RoleService";
import PermissionService from "@/api/PermissionService";
import useCrudApi from "@/composition/UseCrudApi";
import { toRefs, onBeforeUnmount, ref, onMounted } from "@vue/composition-api";
export default {
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    WeeSimpleTable: () => import("@/components/WeeSimpleTable"),
    RoleForm: () => import("./RoleForm")
  },
  setup(props, { refs, root }) {
    const roleService = new RoleService();
    const tableHeaders = [
      {
        column: "name",
        label: "model.role.name",
        searchable: true,
        sortable: true,
        fillable: true
      },
      {
        column: "description",
        label: "model.role.description",
        searchable: true,
        sortable: true,
        fillable: true
      },
      {
        column: "status",
        label: "model.role.status",
        searchable: true,
        sortable: true,
        fillable: true,
        status: true
      },
      {
        label: "base.tool",
        fillable: true,
        baseTool: true
      }
    ];

    // entity
    const initialItem = {
      id: "",
      name: "",
      description: "",
      status: false,
      selectedPermissions: []
    };
    const permissionList = ref([]);

    const { crud } = useCrudApi(
      refs,
      root,
      roleService,
      initialItem,
      tableHeaders
    );

    // fell free to change sort colunm and mode
    // sort.column = "id";
    // sort.mode = "ASC";

    onMounted(() => {
      loadPermissions();
    });
    const loadPermissions = async () => {
      const permissionService = new PermissionService();
      const { response } = await permissionService.getPermissionsCrudtbl();
      if (response) {
        permissionList.value = response;
      }
    };

    onBeforeUnmount(() => {
    });
    return {
      ...toRefs(crud),
      // permission
      permissionList
    };
    // return {
    //   ...toRefs(state),
    //   sort,
    //   pages,
    //   filteredList,
    //   entity,
    //   isProcessing,
    //   searchTxt,
    //   fillableHeaders,
    //   editMode,
    //   //method
    //   fetchData,
    //   openNewForm,
    //   onItemClick,
    //   onOpenNewForm,
    //   onBeforeDeleteItem,
    //   onSave,
    //   advanceSearch,
    //   onReload,
    //   //permission
    //   permissionList
    // };
  }
};
</script>
